import { VNode } from "preact";
import { BUILD_ID } from "./build_id.ts";

export const INTERNAL_PREFIX = "/_frsh";
export const ASSET_CACHE_BUST_KEY = "__frsh_c";

/**
 * Returns true when the current runtime is the browser and false otherwise. This is used for guard runtime-dependent code.
 * Shorthand for the following:
 * `typeof document !== "undefined"`
 *
 * @example
 * ```
 *  if (IS_BROWSER) {
 *    alert('This is running in the browser!');
 *  } else {
 *    console.log('This code is running on the server, no access to window or alert');
 *  }
 * ```
 *
 * Without this guard, alert pauses the server until return is pressed in the console.
 */
export const IS_BROWSER = typeof document !== "undefined";

/**
 * Create a "locked" asset path. This differs from a plain path in that it is
 * specific to the current version of the application, and as such can be safely
 * served with a very long cache lifetime (1 year).
 */
export function asset(path: string): string {
  if (!path.startsWith("/") || path.startsWith("//")) return path;
  try {
    const url = new URL(path, "https://freshassetcache.local");
    if (
      url.protocol !== "https:" || url.host !== "freshassetcache.local" ||
      url.searchParams.has(ASSET_CACHE_BUST_KEY)
    ) {
      return path;
    }
    url.searchParams.set(ASSET_CACHE_BUST_KEY, BUILD_ID);
    return url.pathname + url.search + url.hash;
  } catch (err) {
    console.warn(
      `Failed to create asset() URL, falling back to regular path ('${path}'):`,
      err,
    );
    return path;
  }
}

/** Apply the `asset` function to urls in a `srcset` attribute. */
export function assetSrcSet(srcset: string): string {
  if (srcset.includes("(")) return srcset; // Bail if the srcset contains complicated syntax.
  const parts = srcset.split(",");
  const constructed = [];
  for (const part of parts) {
    const trimmed = part.trimStart();
    const leadingWhitespace = part.length - trimmed.length;
    if (trimmed === "") return srcset; // Bail if the srcset is malformed.
    let urlEnd = trimmed.indexOf(" ");
    if (urlEnd === -1) urlEnd = trimmed.length;
    const leading = part.substring(0, leadingWhitespace);
    const url = trimmed.substring(0, urlEnd);
    const trailing = trimmed.substring(urlEnd);
    constructed.push(leading + asset(url) + trailing);
  }
  return constructed.join(",");
}

export function assetHashingHook(
  vnode: VNode<{
    src?: string;
    srcset?: string;
    ["data-fresh-disable-lock"]?: boolean;
  }>,
) {
  if (vnode.type === "img" || vnode.type === "source") {
    const { props } = vnode;
    if (props["data-fresh-disable-lock"]) return;
    if (typeof props.src === "string") {
      props.src = asset(props.src);
    }
    if (typeof props.srcset === "string") {
      props.srcset = assetSrcSet(props.srcset);
    }
  }
}

// denoCacheMetadata={"headers":{"strict-transport-security":"max-age=63072000; includeSubDomains; preload","x-amz-cf-pop":"LHR50-P6","cross-origin-resource-policy":"same-origin","content-length":"2835","last-modified":"Fri, 22 Mar 2024 21:26:14 GMT","x-amz-cf-id":"ZRSC-KYWGGTqyMvzbiRiozKPTDozWhCt5UkZcALBrSrCbWG3ynEaeQ==","content-type":"application/typescript; charset=utf-8","server":"deno/gcp-europe-west2","x-amz-version-id":"hpJHTuErceHJzUTcQFO4SSZ5mlwjmSFT","access-control-allow-origin":"*","x-amz-replication-status":"COMPLETED","x-content-type-options":"nosniff","x-frame-options":"DENY","referrer-policy":"strict-origin-when-cross-origin","via":"http/2 edgeproxy-h","server-timing":"fetchSource;dur=18","vary":"Accept-Encoding, Origin","age":"30595189","cross-origin-opener-policy":"same-origin","date":"Fri, 22 Mar 2024 21:43:13 GMT","accept-ranges":"bytes","content-security-policy":"default-src 'none'; style-src 'unsafe-inline'; sandbox","x-amz-server-side-encryption":"AES256","x-cache":"Hit from cloudfront","cache-control":"public, max-age=31536000, immutable","cross-origin-embedder-policy":"same-origin","etag":"\"ea7bdf8f9ac8d9bcc8418440231a74a8\""},"url":"https://deno.land/x/fresh@1.6.8/src/runtime/utils.ts","time":1741738981}